import dayjs from 'dayjs'
import 'dayjs/locale/es'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { store } from "./store";
import VueLogger from 'vuejs3-logger';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Theme styles */
import './theme/styles.scss';

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(VueLogger, {
    isEnabled: true,
    logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
  });

dayjs.locale('es')
app.config.globalProperties.$day = dayjs;
app.config.globalProperties.$currency = (value: string | number, currency = 'EUR') => (+value).toLocaleString('es', {
  style: 'currency',
  currency
});

router.isReady().then(() => {
  app.mount('#app');
});
