<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  }
});
</script>

<style lang="scss">
ion-menu.ios ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);

  ion-icon {
    color: var(--ion-color-primary);
  }
}
</style>
