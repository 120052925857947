import { createStore } from "vuex";
import { secureStorage } from '@/store/storage';
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => secureStorage.get(key),
      setItem: (key, value) => secureStorage.set(key, value),
      removeItem: (key) => secureStorage.remove(key)
    }
  })],
});
