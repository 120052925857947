import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/Tabs.vue'),
    children: [
      {
        path: '',
        redirect: '/carta'
      },
      {
        name: 'Menu',
        path: '/carta',
        component: () => import('@/views/FoodMenu.vue'),
        props: true,
      },
      {
        name: 'Search',
        path: '/carta/buscar',
        component: () => import('@/views/Search.vue'),
        props: true,
      },
      {
        name: 'Category',
        path: '/carta/:category',
        component: () => import('@/views/Category.vue'),
        props: true,
      },
      {
        name: 'Product',
        path: '/carta/:category/:slug',
        component: () => import('@/views/Product.vue'),
        props: true,
      },
      {
        name: 'Cart',
        path: '/carrito',
        component: () => import('@/views/Cart.vue'),
        props: true,
      },
      {
        name: 'Club',
        path: '/club',
        component: () => import('@/views/Club/Club.vue'),
        props: true,
      },
      {
        name: 'Account',
        path: '/club/mis-datos',
        component: () => import('@/views/Club/CustomerAccountData.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'Orders',
        path: '/club/mis-pedidos',
        component: () => import('@/views/Club/CustomerOrders.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'Debug',
        path: '/debug',
        component: () => import('@/views/Club/Debug.vue'),
        props: true,
      },
    ]
  },
  // {
  //   path: '/Menu/',
  //   component: Menu,
  // },
  // {
  //   path: '/Destacados/',
  //   component: Featured,
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isAuthenticated) {
      next({name: 'Club'})
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})


export default router
